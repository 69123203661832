<template>
  <div class="favq clearfix">
    <headtopsystemgl></headtopsystemgl>
    <div class="enterprise clearfix">
      <div class="left">
        <systemglleft />
      </div>
      <div class="right">
        <div class="project_main">
          <dl class="jiben_xx">
            <dt>基本信息</dt>
            <dd>
              <h1 class="zhaosh_tit">
                <el-input v-model="name" placeholder="太原市投促局"></el-input>
              </h1>
              <div class="btn">
                <el-button id="but1" @click="editDetail()">保存</el-button>
              </div>
            </dd>
          </dl>
          <ul class="xinzeng_xiajitc">
            <li>
              <span>局内领导：</span>
              <el-input v-model="charge" placeholder="请输入"></el-input>
            </li>
            <li>
              <span>联系方式:</span>
              <el-input v-model="phone" placeholder="请输入"></el-input>
            </li>
            <li>
              <span>总人数：</span>
              <el-input v-model="pnums" placeholder="请输入"></el-input>
            </li>
            <li>
              <span>部门个数：</span>
              <el-input v-model="secnums" placeholder="请输入"></el-input>
            </li>
          </ul>
          <dl class="miaoshu">
            <dt>
              <h1>投促局职能</h1>
            </dt>
            <dd>
              <el-input type="textarea" v-model="func" placeholder="请输入"></el-input>
            </dd>
          </dl>

          <h1 class="main_tit">人员列表</h1>
          <div class="btnss">
            <el-popover placement="bottom" ref="popxz" width="320" trigger="click">
              <ul class="xinzeng_jgoutc">
                <li
                  v-for="(item,index) in listOfStaffdata"
                  :class="uid==item.id? 'hover':''"
                  :key="index"
                  @click="uid = item.id"
                >{{item.real_name}}</li>
              </ul>
              <ul class="xinzeng_tcjitc">
                <li>
                  <span>职务：</span>
                  <el-input v-model="position" placeholder="请输入"></el-input>
                </li>
                <li>
                  <span>职级：</span>
                  <el-select
                    placeholder="请选择"
                    v-model="level"
                    @change="labelchange($event,industryarr,1)"
                  >
                    <el-option
                      v-for="(item,index) in industryarr"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </li>
              </ul>

              <div class="btn">
                <el-button id="but2" @click="close2">取消</el-button>
                <el-button id="but1" @click="addStaffOrg">确定</el-button>
              </div>

              <el-button id="but1" slot="reference">+新增</el-button>
            </el-popover>
          </div>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width:20%;">成员</td>
                <td style="width:20%;">职务</td>
                <td>操作</td>
              </tr>
              <tr v-for="(item,index) in personnellist" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.position}}</td>
                <td>
                  <el-popover :ref="'popsc'+item.id" placement="bottom" width="220" trigger="click">
                    <p class="delt_p">确定删除此角色？</p>
                    <div class="btn">
                      <el-button id="but2" @click="close(item.id)">取消</el-button>
                      <el-button id="but1" @click="getdeleteStaffOrg(item.id)">确定</el-button>
                    </div>

                    <el-button class="huangzi_btn" slot="reference">删除</el-button>
                  </el-popover>
                </td>
              </tr>
            </table>
          </div>

          <h1 class="main_tit">统筹机构管理</h1>
          <div class="btnss">
            <el-popover ref="poptc" placement="right" width="320" trigger="click">
              <ul class="xinzeng_tongchoujgtc">
                <el-checkbox-group v-model="checkList">
                  <li v-for="(item,index) in getCtrllist" :key="index">
                    <el-checkbox :label="item.id" @change="handleCheckAllChange"></el-checkbox>
                    <span>{{item.name}}</span>
                  </li>
                </el-checkbox-group>
              </ul>
              <div class="btn">
                <el-button id="but2" @click="close3">取消</el-button>
                <el-button id="but1" @click="addBeCtrl()">确定</el-button>
              </div>

              <el-button id="but1" slot="reference">+新增</el-button>
            </el-popover>
          </div>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width:20%;">机构名称</td>
                <td style="width:27%;">机构负责人</td>
                <td style="width:25%;">负责人联系电话</td>
                <td>操作</td>
              </tr>
              <tr v-for="(item,index) in listorgctrl" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.charge}}</td>
                <td>{{item.phone}}</td>
                <td>
                  <el-popover :ref="'tcsc'+item.id" placement="bottom" width="220" trigger="click">
                    <p class="delt_p">确定删除此角色？</p>
                    <div class="btn">
                      <el-button id="but2">取消</el-button>
                      <el-button id="but1" @click="deleteCtrl(item.id)">确定</el-button>
                    </div>

                    <el-button class="huangzi_btn" slot="reference">删除</el-button>
                  </el-popover>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  orgDetail,
  orgStaff,
  deleteStaffOrg,
  addStaffOrg,
  listOfStaff,
  listgetRole,
  orgCtrl,
  getCtrl,
  addBeCtrl,
  deleteCtrl,
  editDetail
} from "@/api/index";
export default {
  data() {
    return {
      id: "",
      cate: "",
      name: "",
      charge: "",
      phone: "",
      pnums: "",
      personnellist: [],
      listOfStaffdata: [],
      uid: "",
      level: "",
      position: "",
      industryarr: "",
      listorgctrl: [],
      checkList: [],
      getCtrllist: [],
      func: "",
      secnums: ""
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.cate = this.$route.query.cate;
    this.getorgDetail();
    this.getorgStaff();
    this.getlistOfStaff();
    this.getlistgetRole();
    this.getorgCtrl();
    this.getCtrl();
  },
  methods: {
    //handleCheckAllChange
    handleCheckAllChange() {
    },
    //
    async getorgDetail() {
      const res = await orgDetail({
        id: Number(this.id), //   当前信息的id
        cate: Number(this.cate) //  # 1机构，2部门，3组
      });
      this.name = res.name;
      this.charge = res.charge;
      this.phone = res.phone;
      this.pnums = res.pnums;
      this.secnums = res.secnums;
      this.func = res.func;
    },
    // 人员列表
    async getorgStaff() {
      const res = await orgStaff({
        id: Number(this.id), //   当前信息的id
        cate: Number(this.cate) //  # 1机构，2部门，3组
      });
      this.personnellist = res;
    },
    // 人员删除
    async getdeleteStaffOrg(id) {
      const res = await deleteStaffOrg({
        id: Number(this.id), //   当前信息的id
        cate: Number(this.cate), //  # 1机构，2部门，3组
        uid: id //   当前人员id
      });
      let refName = "popsc" + id;
      this.$refs[refName][0].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getorgStaff();
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    // 新增
    async addStaffOrg() {
      const res = await addStaffOrg({
        id: Number(this.id), //   当前信息的id
        cate: Number(this.cate), //  # 1机构，2部门，3组
        uid: this.uid, //   当前人员id
        level: this.level,
        position: this.position
      });
      let refName = "popxz";
      this.$refs[refName].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getorgStaff();
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    close2() {
      let refName = "popxz";
      this.$refs[refName].doClose();
    },
    // listOfStaff
    async getlistOfStaff() {
      const res = await listOfStaff({
        id: Number(this.id), //   当前信息的id
        cate: Number(this.cate) //  # 1机构，2部门，3组
      });
      this.listOfStaffdata = res;
    },
    // 获取职级
    async getlistgetRole() {
      const res = await listgetRole();
      this.industryarr = res;
    },
    close(id) {
      let refName = "popsc" + id;
      this.$refs[refName][0].doClose();
    },
    // 获取统筹机构列表
    async getorgCtrl() {
      const res = await orgCtrl({
        id: Number(this.id), //   当前信息的id
        cate: Number(this.cate) //  # 1机构，2部门，3组
      });
      this.listorgctrl = res;
    },
    async getCtrl() {
      const res = await getCtrl({
        id: Number(this.id) //   当前信息的id
      });
      this.getCtrllist = res;
    },
    async addBeCtrl() {
      const res = await addBeCtrl({
        id: Number(this.id), //   当前信息的id
        ids: this.checkList //  # 1机构，2部门，3组
      });
      let refName = "poptc";
      this.$refs[refName].doClose();
      this.checkList = [];
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getorgCtrl();
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    close3() {
      let refName = "poptc";
      this.$refs[refName].doClose();
      this.checkList = [];
    },
    // 删除
    async deleteCtrl(id) {
      const res = await deleteCtrl({
        id: this.id,
        bectrl_id: id // 被删除机构的id
      });
      let refName = "tcsc" + id;
      if (this.$refs[refName].length) {
      }
      this.$refs[refName][0].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getorgCtrl();
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    // 保存
    async editDetail() {
      const res = await editDetail({
        id: Number(this.id), //   当前信息的id
        cate: Number(this.cate), //  # 1机构，2部门，3组
        name: this.name, //")  # 1机构，2部门，3组  的名称
        charge: this.charge, //")  # 局内领导
        charge_phone: this.phone, //")  # 联系方式
        func: this.func, //")  # 部门职能
        pnums: this.pnums, //")  # 人数
        secnums: this.secnums //")  # 部门组人数
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getorgCtrl();
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  min-height: 100%;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;

  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.zuzhi_btns {
  display: inline-block;
  float: left;
  padding: 0 15px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 2% 0 1% 0;
  cursor: pointer;
}

.jiben_xx {
  overflow: hidden;
  zoom: 1;
}
.jiben_xx dt {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.jiben_xx dd .btn {
  float: right;
}
.jiben_xx dd .zhaosh_tit {
  margin: 15px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  float: left;
}
.jiben_xx dd .zhaosh_tit .el-input {
  width: 300px;
  height: 46px;
  line-height: 46px;
}
.jiben_xx dd .zhaosh_tit/deep/.el-input input {
  height: 46px;
  line-height: 46px;
}

.main_tit {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.right {
  background: #fff;
  width: calc(100% - 256px - 15px);
  min-height: 89vh;
  float: right;
  margin-left: 10px;

  table td {
    font-size: 14px;
    line-height: 44px;
  }
  .project_main {
    padding: 10px 22px;
    min-height: 80%;
    overflow: hidden;
    zoom: 1;

    .project_tables {
      font-size: 14px;
      line-height: 44px;
      margin-bottom: 25px;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
        }
      }
      span {
        margin: 0 3%;
      }
      .chengzi_btn {
        color: #fe6a00;
        cursor: pointer;
      }
      .lvzi_btn {
        color: #2cb54c;
        cursor: pointer;
      }
      .zizi_btn {
        color: #135de6;
        cursor: pointer;
      }
      .redzi_btn {
        color: #e51313;
        cursor: pointer;
      }
      .bluezi_btn {
        color: #0ebbf3;
        cursor: pointer;
      }
      .huangzi_btn {
        color: #e69554;
        cursor: pointer;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }
    .project_tables td:first-child {
      text-align: left;
      padding-left: 60px;
    }
    .project_tables td img {
      margin-right: 5px;
    }

    //分页
    /deep/.el-pagination {
      float: right;
      margin-top: 20px;
    }
    /deep/.btn-prev {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.btn-next {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.el-pager li {
      border: 1px solid #888;
      margin: 0 5px;
    }
    /deep/.el-pager li.active,
    /deep/.el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}

.dispatch {
  width: 100%;
  height: 138px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  z-index: 99;
  margin-top: 15px;
  ul {
    width: 100%;
    height: 138px;
    padding: 10px;
    overflow: auto;
    li {
      list-style: none;
      cursor: pointer;
      height: 30px;
      line-height: 20px;
      width: 100%;
      padding: 5px;
      &:hover {
        background-color: #eee;
        color: #e69554;
      }
      &.active {
        color: #e69554;
        background-color: #eee;
      }
    }
  }
}
.btn {
  text-align: center;
  margin: 20px 0 15px 0;
}
.btnss {
  text-align: left;
  margin: 20px 0 15px 0;
}
#but1 {
  width: 72px;
  height: 30px;
  background: #fe6a00;
  border: 1px solid #fe6a00;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  padding: 0;
  margin-right: 10px;
}
#but2 {
  width: 72px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #fe6a00;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  color: #fe6a00;
  line-height: 30px;
  padding: 0;
}

.branch {
  overflow: auto;
  ul {
    height: auto;
  }
}
.el-collapse-item {
  padding: 0 10px;
}
.project_tables /deep/ .el-checkbox__label {
  display: none;
}

.xinzeng_xiajitc {
  background: #f2f4f7;
  margin-top: 5px;
  overflow: hidden;
  zoom: 1;
  margin-bottom: 15px;
  padding: 15px;
}
.xinzeng_xiajitc li {
  list-style: none;
  margin-right: 20px;
  overflow: hidden;
  zoom: 1;
  line-height: 34px;
  float: left;
}
.xinzeng_xiajitc li span {
  line-height: 34px;
}
.xinzeng_xiajitc li .el-input {
  width: 65%;
}
.xinzeng_xiajitc li .el-select {
  width: 65%;
}
.xinzeng_xiajitc li .el-textarea {
  width: 65%;
}
.tc_tit {
  margin-bottom: 5px;
}

.xinzeng_jgoutc {
  background: #fafcff;
  padding: 10px;
  line-height: 34px;
  height: 110px;
  overflow-y: auto;
  margin-bottom: 15px;
}
.xinzeng_jgoutc li {
  list-style: none;
  padding-left: 10px;
}
.xinzeng_jgoutc li.hover,
.xinzeng_jgoutc li:hover {
  background: #eee;
  color: #fe6a00;
}

.xinzeng_tcjitc {
  margin-top: 5px;
  overflow: hidden;
  zoom: 1;
  margin-bottom: 15px;
}
.xinzeng_tcjitc li {
  list-style: none;
  margin-right: 20px;
  overflow: hidden;
  zoom: 1;
  line-height: 34px;
  margin-bottom: 10px;
}
.xinzeng_tcjitc li span {
  line-height: 34px;
  display: block;
  width: 85px;
  float: left;
  padding-left: 15px;
}
.xinzeng_tcjitc li .el-input {
  width: 65%;
}
.xinzeng_tcjitc li .el-select {
  width: 65%;
}

.xinzeng_tongchoujgtc {
  background: #fafcff;
  padding: 10px;
  line-height: 28px;
  height: 130px;
  overflow-y: auto;
  li {
    height: 30px;
    color: #222222;
    span {
      font-size: 14px;
      display: inline-block;
    }
  }
}
.xinzeng_tongchoujgtc .el-checkbox {
  margin-right: 5px;
}

.quanx_check {
  margin-left: 20px;
}
.quanx_check span {
  margin-right: 25px;
}

/deep/.el-textarea__inner:focus {
  border-color: #fe6a00;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fe6a00;
  border-color: #fe6a00;
}
/deep/.el-collapse-item__content {
  padding-bottom: 0;
}
/deep/.el-collapse-item__arrow {
  display: none;
}
/deep/.el-button {
  border: 0;
}
/deep/.el-input {
  width: 100%;
  input {
    border-radius: 0;
    width: 100%;
    height: 30px;
    color: #333;
    background: #ffffff;
    border: 1px solid #dddddd;
  }
  ::-webkit-input-placeholder {
    color: #333;
  }
  /deep/.el-icon-circle-close {
    background: url("../../assets/merchantsradar/cuo.png") 50% 50% no-repeat;
  }
  .el-icon-circle-close:before {
    content: "";
  }
}
/deep/.el-select {
  width: 70%;
  height: 30px;
  input {
    width: 100%;
    height: 30px;
    background: #ffffff;
    border: 1px solid #dddddd;
  }
  /deep/.el-input__icon {
    line-height: 30px !important;
  }
}
/deep/ .el-checkbox__label {
  display: none;
}

.miaoshu {
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 25px;
}
.miaoshu dt {
  overflow: hidden;
  zoom: 1;
  margin-bottom: 10px;
  text-align: left;
}
.miaoshu dt h1 {
  font-size: 16px;
  font-weight: bold;
  float: left;
}
.miaoshu dt span {
  float: right;
}
.bianj_btns {
  display: inline-block;
  padding: 0 22px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.miaoshu dd {
  text-align: left;
  padding: 0 10px;
}
</style>